export const FILE_FORMATS = {
  PLY: 'PLY',
  STL: 'STL',
  JPEG: 'JPEG',
  JPG: 'JPG',
  GIF: 'GIF',
  PNG: 'PNG',
  PDF: 'PDF',
  MP4: 'MP4',
  WEBM: 'WEBM',
  MOV: 'MOV',
} as const;

import { NODE_ENV } from '@/constants/nodeEnv';
import { getEnv } from '@/utils/env';

const BASE_URL = 'witmetrics.io';
const LOCAL_URL = 'http://localhost';

export const getAPIURL = () => {
  return getURL(3333, 'api', '/v1');
};

export const getAuthURL = () => {
  return getURL(3100, 'auth');
};

export const getAccountsURL = () => {
  return getURL(3200, 'accounts');
};

export const getAdminURL = () => {
  return getURL(3300, 'admin');
};

export const getCRMURL = () => {
  return getURL(3400, 'crm');
};

export const getPerspectiveURL = () => {
  return getURL(3500, 'perspective');
};

export const getUnisonURL = () => {
  return getURL(3600, 'unison');
};

export const getInsightsURL = () => {
  return getURL(3700, 'insights');
};

export const getSchedulerURL = () => {
  return getURL(3800, 'scheduler');
};

function getURL(port: number, subdomain: string, path: string = '') {
  const env = getEnv();
  switch (env) {
    case NODE_ENV.DEVELOPMENT:
      return `${LOCAL_URL}:${port}${path}`;
    case NODE_ENV.TESTING:
      return `https://${subdomain}.testing.${BASE_URL}${path}`;
    case NODE_ENV.PRODUCTION:
      return `https://${subdomain}.${BASE_URL}${path}`;
    default:
      throw Error(`Unrecognized environment: "${env}"`);
  }
}

export const getProfilePicURL = (source: string) => {
  return `${getAPIURL()}/files/profile-picture?key=${encodeURIComponent(
    source
  )}`;
};

export const getPracticeLogoURL = (source: string) => {
  return `${getAPIURL()}/files/practice-logo/${encodeURIComponent(source)}`;
};

import { FILTER_TYPES } from '@/constants/filterTypes';
import type {
  ColumnFilter,
  DecoratedUnisonProjectTask,
  PaginatedResponse,
  UndecoratedUser,
} from '@witmetrics/api-client';
import { renderUserName } from '@/utils/strings';
import { filterBlanks } from '@/utils/arrays';

type ResponsePagination = PaginatedResponse<any>['pagination'];

export type UserOption = Pick<UndecoratedUser, 'id' | 'firstname' | 'lastname'>;

export interface Pagination
  extends Omit<ResponsePagination, 'pageCount' | 'rowCount'> {
  pageCount?: number;
  rowCount?: number;
}

export function buildColumnFilter<T>(
  column: string,
  comparisonOperator: ColumnFilter['comparisonOperator'],
  value: T
) {
  return {
    column,
    comparisonOperator,
    value,
    filterType: FILTER_TYPES.COLUMN_VALUE,
  };
}

export function buildUserOption({ id, firstname, lastname }: UserOption) {
  return {
    value: id,
    label: renderUserName({ firstname, lastname }),
  };
}

export function filterTasks(
  tasks: DecoratedUnisonProjectTask[],
  statuses: boolean[],
  users: number[]
) {
  return filterBlanks(tasks).filter(({ task }) => {
    if (statuses.length === 0 && users.length === 0) return true;
    const includesStatus = statuses.includes(task.isResolved);
    const includesAssignedTo = users.includes(task.assignedTo.id);
    return includesStatus && includesAssignedTo;
  });
}

export function getTaskFilterCount(
  statusFilter: boolean[],
  assignedToFilter: number[],
  practiceUsers: any[]
) {
  let count = 0;
  if (statusFilter.length === 1) count++;
  if (assignedToFilter.length !== practiceUsers.length) {
    count += assignedToFilter.length;
  }
  return count;
}

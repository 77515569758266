import {
  type Id as ToastID,
  toast,
  type ToastContent,
  type ToastOptions,
} from 'react-toastify';

export function clearToasters() {
  return toast.dismiss();
}

export function closeToaster(id: ToastID) {
  return toast.dismiss(id);
}

export function showToaster(message: ToastContent, options?: ToastOptions) {
  return toast(message, options);
}

export function showSuccessToaster(message: string, options?: ToastOptions) {
  return toast.success(message, options);
}

export function showErrorToaster(message: string, options?: ToastOptions) {
  return toast.error(message, options);
}

export function showWarningToaster(message: string, options?: ToastOptions) {
  return toast.warn(message, options);
}

export function showInfoToaster(message: string, options?: ToastOptions) {
  return toast.info(message, options);
}

import type { Config } from 'tailwindcss';
import colors from 'tailwindcss/colors';

export default {
  content: ['./src/**/*.{js,jsx,ts,tsx}'],
  theme: {
    colors: {
      grey: colors.gray,
    },
    extend: {
      backgroundImage: {
        'default-gradient': 'url(./../public/images/gradient-1_sm.png)',
      },
      borderRadius: {
        lg: '0.5rem',
        xl: '0.75rem',
        '2xl': '1rem',
        '3xl': '1.5rem',
      },
      boxShadow: {
        card: '0px 3px 8px 0px hsla(265, 10%, 23%, 0.15)',
        'pop-up': '0px 4px 10px 0px hsla(0,0%,0%,0.15)',
        dialog: '0px 6px 15px 0px hsla(0,0%,0%,0.2)',
        sidebar: '0px 4px 20px 0 hsla(0,0%,0%,10%)',
        header: '0px 1px 8px 0 hsla(0,0%,0%,10%)',
      },
      transitionProperty: {
        width: 'width',
      },
      colors: {
        transparent: 'transparent',
        white: '#fff',
        black: '#1b2932',
        // 'site-background': '#f8f9fa',
        'site-background': '#fafafb',
        'dialog-backdrop': '#15181C',
        brand: {
          blue: '#002255',
          orange: '#ff9068',
        },
        blue: {
          1: '#6bc1f8',
          2: '#10a2fd',
          3: '#357dd2',
          4: '#255fb0',
        },
        cyan: {
          1: '#a5f3fc',
          2: '#22d3ee',
          3: '#06b6d4',
          4: '#0e7490',
        },
        emerald: {
          1: '#a7f3d0',
          2: '#6ee7b7',
          3: '#34d399',
          4: '#059669',
        },
        green: {
          1: '#b4f3cf',
          2: '#58bd85',
          3: '#4aa37f',
          4: '#3a8474',
        },
        lime: {
          1: '#d9f99d',
          2: '#bef264',
          3: '#a3e635',
          4: '#84cc16',
        },
        navy: {
          1: '#6c8fe0',
          2: '#234aa4',
          3: '#1a377a',
          4: '#0c2254',
        },
        orange: {
          1: '#ffd1b7',
          2: '#f97b19',
          3: '#ff5900',
          4: '#cc4700',
        },
        pink: {
          1: '#fecdd3',
          2: '#fb7195',
          3: '#f43f5e',
          4: '#e11d48',
        },
        purple: {
          1: '#869af4',
          2: '#5370f4',
          3: '#475ece',
          4: '#3449ae',
        },
        red: {
          1: '#feb3af',
          2: '#f2625a',
          3: '#df2a20',
          4: '#bd180f',
        },
        yellow: {
          1: '#fef08a',
          2: '#fde047',
          3: '#facc15',
          4: '#eab308',
        },
        grey: {
          1: '#fafafa',
          // 2: '#f8f9fc',
          2: '#f3f5f7',
          3: '#e2e6e9',
          4: '#cedfe8',
          5: '#a7c1d2',
          6: '#7e9eb5',
          7: '#496f88',
          8: '#2d4453',
          9: '#121b21',
        },
      },
    },
  },
  plugins: [],
} satisfies Config;
